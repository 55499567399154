import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import { StationianLightTheme } from './themes/Stationian';
import i18n from './i18n';
import './index.css';
import LoadingIndicatorPageWithDelay from './pages/LoadingIndicatorPageWithDelay';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={StationianLightTheme}>
          <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            style={
              window.matchMedia('(max-width: 600px)').matches && {
                marginBottom: '90px',
              }
            }
          >
            <Suspense fallback={<LoadingIndicatorPageWithDelay />}>
              <Router>
                {
                  !process.env.REACT_APP_IS_CHROME_EXTENSION
                    ? (<App />)
                    : (
                      <div style={{ width: '350px', height: '550px', margin: 0 }}>
                        <App />
                      </div>
                    )
                }
              </Router>
            </Suspense>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
